@mixin mq($size) {
  @media only screen and (max-width: $size) {
    @content;
  }
}



.footer {
  background-color: #5C2D77;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23E62987'/%3E%3Cstop offset='1' stop-color='%235C2D77'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%23FDC603' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23FDC603' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.3'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .75rem;
  flex-direction: column;
  padding: 6rem 2rem 2rem;
  height: var(--footerHeight);

  strong {
    font-weight: 500;
  }

  * {
    color: var(--color-extra03)
  }

  .footer-content {

    h3 {
      font-size: 2.1rem;
    }

    font-size: 1.4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 90%;
    @include mq(1280px) {
      width: 90%;
    }
    @include mq(768px) {
      width: var(--contentWidth);
    }

    .top {
      .column-1 p {
        font-size: 1.275rem;
        font-weight: 200;
      }

      display: flex;
      justify-content: space-between;
      gap: 2rem;
      @include mq(1080px) {
        flex-wrap: wrap;
        justify-content: center;
      }
      @include mq(650px) {
        display: block;
      }

      .column {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        flex: 1;

        h3 {
          margin: 0;
        }
      }

      .column-1 {
        flex: 1.5 !important;

        .logo-container {
          display: flex;
          align-items: center;
          gap: 1rem;

          .logo-img {
            width: 3.25rem;
          }
        }

        p {
          padding-right: 4rem;
          text-align: justify;
          margin: 0;
        }

        .links {
          display: flex;
          gap: 0.6rem;

          a {
            display: flex;
            align-items: center;
            gap: 0.6rem;
          }
        }
      }

      .column-2 {

        .links {
          display: flex;
          flex-direction: column;
          gap: 0.6rem;

          a {
            display: flex;
            align-items: center;
            gap: 0.6rem;
          }
        }

      }

      @include mq(1080px) {
        .column {
          width: 50% !important;
        }
        .column-1, .column-3 {

        }
      }

      .column-3 {

        .links {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          a {
            display: flex;
            align-items: center;
            gap: 1rem;
            cursor: pointer;
          }
        }
      }

      .column-4 {
        .links {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          a {
            display: flex;
            align-items: center;
            gap: 1rem;
            white-space: nowrap;
          }
        }
      }

      @include mq(768px) {
        justify-content: flex-start !important;
        .column {
          width: 100% !important;
        }
        .column-1, .column-3 {
        }
        .column-1 {
          p {
            padding: 0 !important;
          }
        }
      }
      @include mq(650px) {
        .column {
        }
        .column-1, .column-3 {
        }
      }
      @include mq(580px) {
        .column {
        }
        .column-1, .column-3 {
        }
      }
      @include mq(510px) {
        .column-1 {
          p {
            padding: 0;
          }
        }
        .column {
          width: 100% !important;
        }
        .column-2, .column-3, .column-4 {
        }
      }
      @include mq(420px) {
        .column {
        }
      }
    }

    .bottom {
      display: flex;
      flex-direction: column;
      gap: .5rem;

      * {
        text-align: center;
      }
    }
  }

  .happy {
    display: flex;
    align-items: center;
    justify-content: center;

    .watermark {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: .25rem;

      img {
        width: 22.25px;
        aspect-ratio: 1 / 1;
        margin-left: .25rem !important;
      }

      span {
        color: #f1b44c;
        margin-right: .25rem !important;
        font-weight: 600;
      }

    }
  }
}
